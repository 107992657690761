import * as React from 'react'
import LayoutRoot from '../components/LayoutRoot'
import * as styles from './../styles/404.module.scss'

const NotFoundPage = () => (
  <LayoutRoot>
    <div className={styles.div}>
      <h3>找不到页面</h3>
      <p>请您移步到其他页面</p>
    </div>
  </LayoutRoot>
)

export default NotFoundPage
